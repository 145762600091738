import {
  GetInteractor,
  PaginationOffsetLimit,
} from "../../../../../harmony/core";
import { SortByDateMode } from "../../../../shared/enums/campaign.enums";
import { BrandService } from "../../../../shared/services/api/brand.service";
import { GetAllLandingPageQuery } from "../../data/queries/landing-page-network.query";
import { LandingPageSummary } from "../models/landing-page.summary";
import { BrandNotSelectedError } from "../../../../shared/errors/brand-not-selected-error";

export class GetLandingPageSummariesInteractor {
  constructor(
    private readonly getLandingPages: GetInteractor<
      PaginationOffsetLimit<LandingPageSummary>
    >,
    private readonly brandService: BrandService,
  ) {}

  public async execute(
    offset: number,
    limit: number,
    searchFilter = "",
    sortMode = SortByDateMode.Desc,
  ): Promise<PaginationOffsetLimit<LandingPageSummary>> {
    const brandId = this.brandService.currentBrandId;
    if (!brandId) {
      throw new BrandNotSelectedError();
    }

    return this.getLandingPages
      .execute(
        new GetAllLandingPageQuery(
          offset,
          limit,
          brandId!,
          searchFilter,
          sortMode,
        ),
      )
      .catch((responseWithError) => {
        if (responseWithError.status === 404) {
          return new PaginationOffsetLimit([], 0, 0, 0);
        }

        throw responseWithError;
      });
  }
}
