import { DeleteInteractor } from "../../../../../harmony/core";
import { DeleteFileQuery } from "../../data/queries/file-network.query";

export class DeleteFileInteractor {
  constructor(private readonly deleteFile: DeleteInteractor) {}

  async execute(fileUrl: string): Promise<void> {
    return this.deleteFile.execute(new DeleteFileQuery(fileUrl));
  }
}
