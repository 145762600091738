export class LandingPageEntity {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly uuid: string,
    public readonly brandId: number,
    public readonly status: string,
    public readonly associatedCampaigns?: string[],
    public readonly uri?: string,
  ) {}
}
