import { GetInteractor } from "../../../../../harmony/core";
import { LandingPageService } from "../../../../landing-page/services/landing-page.service";
import { GetAllLandingPageBlockQuery } from "../../data/queries/landing-page-block-network.query";
import { LandingPageBlock } from "../models/landing-page.block";

export class GetAllLandingPageBlocksInteractor {
  constructor(
    private readonly getLandingPageBlocks: GetInteractor<LandingPageBlock[]>,
    private readonly landingPageService: LandingPageService,
  ) {}

  public async execute(landingPageId: number): Promise<LandingPageBlock[]> {
    const landingPageBlocks = await this.getLandingPageBlocks.execute(
      new GetAllLandingPageBlockQuery(landingPageId),
    );

    this.landingPageService.setCurrentLandingPageBlocks(landingPageBlocks);
    return landingPageBlocks;
  }
}
