import { ColorPalette } from "../../domain/models/color-palette";
import { ColorPaletteEntity } from "../entities/color-palette.entity";

export class ColorPaletteEntityToColorPaletteMapper {
  public map(from: ColorPaletteEntity): ColorPalette {
    return new ColorPalette(
      from?.backgroundColor,
      from?.textColor,
      from?.buttonColor,
    );
  }
}

export class ColorPaletteToColorPaletteEntityMapper {
  public map(from: ColorPalette): ColorPaletteEntity {
    return new ColorPaletteEntity(
      from?.textColor,
      from?.backgroundColor,
      from?.buttonColor,
    );
  }
}
