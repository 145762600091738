import { NgModule } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiRequestService } from "../../../harmony/core";
import { DefaultLandingPageBuilderHeaderPresenter } from "../../landing-page/components/landing-page-builder-header/landing-page-builder-header.presenter";
import { DefaultLandingPageBuilderSidebarPresenter } from "../../landing-page/components/landing-page-builder-sidebar/landing-page-builder-sidebar.presenter";
import { DefaultLandingPageBaseBlockPresenter } from "../../landing-page/pages/landing-page-blocks/landing-page-base-block.presenter";
import { DefaultLandingPageBlockConfigPresenter } from "../../landing-page/pages/landing-page-blocks/landing-page-block-config/landing-page-block-config.presenter";
import { DefaultLandingPageBuilderContainerPresenter } from "../../landing-page/pages/landing-page-builder-container/landing-page-builder-container.presenter";
import { DefaultLandingPageListPresenter } from "../../landing-page/pages/landing-page-list/landing-page-list.presenter";
import { LandingPageService } from "../../landing-page/services/landing-page.service";
import { BrandService } from "../../shared/services/api/brand.service";
import { NotificationService } from "../../shared/services/notification.service";
import { DefaultRouterService } from "../../shared/services/router.service";
import { DeleteFileInteractor } from "../file/domain/interactors/delete-file.interactor";
import { CreateLandingPageBlockInteractor } from "./domain/interactors/create-landing-page-block.interactor";
import { CreateLandingPageInteractor } from "./domain/interactors/create-landing-page.interactor";
import { DeleteLandingPageBlockInteractor } from "./domain/interactors/delete-landing-page-block.interactor";
import { DeleteLandingPageInteractor } from "./domain/interactors/delete-landing-page.interactor";
import { GetAllLandingPageBlocksInteractor } from "./domain/interactors/get-all-landing-page-blocks.interactor";
import { GetLandingPageBlockInteractor } from "./domain/interactors/get-landing-page-block.interactor";
import { GetLandingPageSummariesInteractor } from "./domain/interactors/get-landing-page-summaries.interactor";
import { GetLandingPageInteractor } from "./domain/interactors/get-landing-page.interactor";
import { PublishLandingPageInteractor } from "./domain/interactors/publish-landing-page.interactor";
import { UnpublishLandingPageInteractor } from "./domain/interactors/unpublish-landing-page.interactor";
import { UpdateAllLandingPageBlocksInteractor } from "./domain/interactors/update-all-landing-page-blocks.interactor";
import { UpdateLandingPageBlockInteractor } from "./domain/interactors/update-landing-page-block.interactor";
import { UpdateLandingPageInteractor } from "./domain/interactors/update-landing-page.interactor";
import {
  LandingPageDefaultProvider,
  LandingPageProvider,
} from "./landing-page.provider";

export const LandingPageBuilderHeaderPresenterProvider = {
  provide: "LandingPageBuilderHeaderPresenter",
  deps: [
    PublishLandingPageInteractor,
    UnpublishLandingPageInteractor,
    LandingPageService,
    UpdateLandingPageInteractor,
    NotificationService,
    MatDialog,
    DefaultRouterService,
  ],
  useFactory: (
    publishLandingPage: PublishLandingPageInteractor,
    unpublishLandingPage: UnpublishLandingPageInteractor,
    landingPageService: LandingPageService,
    updateLandingPage: UpdateLandingPageInteractor,
    notificationService: NotificationService,
    dialogService: MatDialog,
    routerService: DefaultRouterService,
  ) =>
    new DefaultLandingPageBuilderHeaderPresenter(
      publishLandingPage,
      unpublishLandingPage,
      landingPageService,
      updateLandingPage,
      notificationService,
      dialogService,
      routerService,
    ),
};

export const LandingPageListPresenterProvider = {
  provide: "LandingPageListPresenter",
  deps: [
    BrandService,
    NotificationService,
    GetLandingPageSummariesInteractor,
    CreateLandingPageInteractor,
    MatDialog,
    DefaultRouterService,
  ],
  useFactory: (
    brandService: BrandService,
    notificationService: NotificationService,
    getLandings: GetLandingPageSummariesInteractor,
    createLanding: CreateLandingPageInteractor,
    dialogService: MatDialog,
    routerService: DefaultRouterService,
  ) =>
    new DefaultLandingPageListPresenter(
      brandService,
      notificationService,
      getLandings,
      createLanding,
      dialogService,
      routerService,
    ),
};

export const LandingPageBuilderSidebarPresenterProvider = {
  provide: "LandingPageBuilderSidebarPresenter",
  deps: [
    UpdateAllLandingPageBlocksInteractor,
    Router,
    DeleteLandingPageBlockInteractor,
    LandingPageService,
  ],
  useFactory: (
    updateLandingPageBlocks: UpdateAllLandingPageBlocksInteractor,
    router: Router,
    deleteLandingPageBlock: DeleteLandingPageBlockInteractor,
    landingPageService: LandingPageService,
  ) =>
    new DefaultLandingPageBuilderSidebarPresenter(
      updateLandingPageBlocks,
      router,
      deleteLandingPageBlock,
      landingPageService,
    ),
};

export const LandingPageBuilderContainerPresenterProvider = {
  provide: "LandingPageBuilderContainerPresenter",
  deps: [
    GetLandingPageInteractor,
    GetAllLandingPageBlocksInteractor,
    LandingPageService,
  ],
  useFactory: (
    getLandingPage: GetLandingPageInteractor,
    getLandingPageBlocks: GetAllLandingPageBlocksInteractor,
    landingPageService: LandingPageService,
  ) =>
    new DefaultLandingPageBuilderContainerPresenter(
      getLandingPage,
      getLandingPageBlocks,
      landingPageService,
    ),
};

export const LandingPageBlockConfigPresenterProvider = {
  provide: "LandingPageBlockConfigPresenter",
  deps: [LandingPageService, UpdateLandingPageInteractor],
  useFactory: (
    landingPageService: LandingPageService,
    updateLandingPage: UpdateLandingPageInteractor,
  ) =>
    new DefaultLandingPageBlockConfigPresenter(
      landingPageService,
      updateLandingPage,
    ),
};

export const LandingPageBaseBlockPresenterProvider = {
  provide: "LandingPageBaseBlockPresenter",
  deps: [
    LandingPageService,
    UpdateLandingPageBlockInteractor,
    DeleteFileInteractor,
    Router,
    ActivatedRoute,
  ],
  useFactory: (
    landingPageService: LandingPageService,
    updateBlock: UpdateLandingPageBlockInteractor,
    deleteFile: DeleteFileInteractor,
    router: Router,
    route: ActivatedRoute,
  ) =>
    new DefaultLandingPageBaseBlockPresenter(
      landingPageService,
      updateBlock,
      deleteFile,
      router,
      route,
    ),
};

export const LANDING_PAGE_PROVIDERS = [
  {
    provide: GetLandingPageSummariesInteractor,
    deps: [LandingPageProvider, BrandService],
    useFactory: (p: LandingPageProvider, brandService: BrandService) =>
      p.provideGetLandingPageSummaries(brandService),
  },
  {
    provide: GetLandingPageInteractor,
    deps: [LandingPageProvider, LandingPageService],
    useFactory: (
      p: LandingPageProvider,
      landingPageService: LandingPageService,
    ) => p.provideGetLandingPage(landingPageService),
  },
  {
    provide: CreateLandingPageInteractor,
    deps: [LandingPageProvider, BrandService],
    useFactory: (p: LandingPageProvider, brandService: BrandService) =>
      p.provideCreateLandingPage(brandService),
  },
  {
    provide: UpdateLandingPageInteractor,
    deps: [LandingPageProvider, LandingPageService],
    useFactory: (
      p: LandingPageProvider,
      landingPageService: LandingPageService,
    ) => p.provideUpdateLandingPage(landingPageService),
  },
  {
    provide: PublishLandingPageInteractor,
    deps: [LandingPageProvider],
    useFactory: (p: LandingPageProvider) => p.providePublishLandingPage(),
  },
  {
    provide: UnpublishLandingPageInteractor,
    deps: [LandingPageProvider],
    useFactory: (p: LandingPageProvider) => p.provideUnpublishLandingPage(),
  },
  {
    provide: DeleteLandingPageInteractor,
    deps: [LandingPageProvider],
    useFactory: (p: LandingPageProvider) => p.provideDeleteLandingPage(),
  },
];

export const LANDING_PAGE_BLOCK_PROVIDERS = [
  {
    provide: CreateLandingPageBlockInteractor,
    deps: [LandingPageProvider, LandingPageService],
    useFactory: (
      p: LandingPageProvider,
      landingPageService: LandingPageService,
    ) => p.provideCreateLandingPageBlock(landingPageService),
  },
  {
    provide: GetAllLandingPageBlocksInteractor,
    deps: [LandingPageProvider, LandingPageService],
    useFactory: (
      p: LandingPageProvider,
      landingPageService: LandingPageService,
    ) => p.provideGetAllLandingPageBlocks(landingPageService),
  },
  {
    provide: GetLandingPageBlockInteractor,
    deps: [LandingPageProvider],
    useFactory: (p: LandingPageProvider) => p.provideGetLandingPageBlock(),
  },
  {
    provide: UpdateLandingPageBlockInteractor,
    deps: [LandingPageProvider, LandingPageService],
    useFactory: (
      p: LandingPageProvider,
      landingPageService: LandingPageService,
    ): UpdateLandingPageBlockInteractor =>
      p.provideUpdateLandingPageBlock(landingPageService),
  },
  {
    provide: UpdateAllLandingPageBlocksInteractor,
    deps: [LandingPageProvider, LandingPageService],
    useFactory: (
      p: LandingPageProvider,
      landingPageService: LandingPageService,
    ) => p.provideUpdateAllLandingPageBlocks(landingPageService),
  },
  {
    provide: DeleteLandingPageBlockInteractor,
    deps: [LandingPageProvider, LandingPageService],
    useFactory: (
      p: LandingPageProvider,
      landingPageService: LandingPageService,
    ) => p.provideDeleteLandingPageBlock(landingPageService),
  },
];

@NgModule({
  providers: [
    ...LANDING_PAGE_PROVIDERS,
    ...LANDING_PAGE_BLOCK_PROVIDERS,
    {
      provide: LandingPageProvider,
      deps: [ApiRequestService],
      useFactory: (apiRequest: ApiRequestService): LandingPageProvider =>
        new LandingPageDefaultProvider(apiRequest),
    },
  ],
})
export class LandingPageProviderModule {}
