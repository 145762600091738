import { DeleteInteractor } from "../../../../../harmony/core";
import { LandingPageService } from "../../../../landing-page/services/landing-page.service";
import { DeleteLandingPageBlockQuery } from "../../data/queries/landing-page-block-network.query";

export class DeleteLandingPageBlockInteractor {
  constructor(
    private readonly deleteBlock: DeleteInteractor,
    private readonly landingPageService: LandingPageService,
  ) {}

  public async execute(blockId: number): Promise<void> {
    await this.deleteBlock.execute(new DeleteLandingPageBlockQuery(blockId));
    await this.landingPageService.deleteBlock(blockId);
  }
}
