import { DeleteNetworkQuery } from "../../../../../harmony/core";
import { ApiEndpoint } from "../../../api-endpoint";

export class DeleteFileQuery extends DeleteNetworkQuery {
  constructor(public readonly url: string) {
    super(ApiEndpoint.FileCdnDelete);
  }

  public get queryParameters(): Record<string, string> {
    return {
      filePublicUrl: this.url,
    };
  }
}
