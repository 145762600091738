import { LandingPage, LandingPageStatus } from "./landing.page";

export class LandingPageSummary extends LandingPage {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly uuid: string,
    public readonly brandId: number,
    public readonly status: LandingPageStatus,
    public readonly logoBlockId: number,
    public readonly associatedCampaigns?: string[],
    public readonly uri?: string,
    public readonly backgroundImgUrl?: string,
    public readonly logoUrl?: string,
  ) {
    super(id, title, uuid, brandId, status, associatedCampaigns, uri);
  }
}
