import { FontEntity } from "../entities/font.entity";
import { Font } from "../../domain/models/font";

export class FontEntityToFontMapper {
  public map(from: FontEntity): Font {
    return new Font(
      from?.name,
      from?.fontFamily,
      from?.fontFamilyBold,
      // Default font for retro-compatibility
      from?.buttonFontFamily ?? from?.fontFamilyBold,
    );
  }
}

export class FontToFontEntityMapper {
  public map(from: Font): FontEntity {
    return new FontEntity(
      from.name,
      from.fontFamily,
      from.fontFamilyBold,
      from.buttonFontFamily,
    );
  }
}
