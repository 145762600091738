import { DeleteInteractor } from "../../../../../harmony/core";
import { DeleteLandingPageQuery } from "../../data/queries/landing-page-network.query";

export class DeleteLandingPageInteractor {
  constructor(private readonly deleteLandingPage: DeleteInteractor) {}

  public async execute(landingPageId: number): Promise<void> {
    return this.deleteLandingPage.execute(
      new DeleteLandingPageQuery(landingPageId),
    );
  }
}
