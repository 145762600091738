import { PutInteractor } from "../../../../../harmony/core";
import { LandingPageService } from "../../../../landing-page/services/landing-page.service";
import { UpdateLandingPageQuery } from "../../data/queries/landing-page-network.query";
import { LandingPage } from "../models/landing.page";

export class UpdateLandingPageInteractor {
  constructor(
    private readonly updateLandingPage: PutInteractor<LandingPage>,
    private readonly landingPageService: LandingPageService,
  ) {}

  public async execute(landingPage: LandingPage): Promise<LandingPage> {
    const updatedLandingPage = await this.updateLandingPage.execute(
      undefined,
      new UpdateLandingPageQuery(landingPage.id, landingPage.title),
    );
    this.landingPageService.setCurrentLandingPage(updatedLandingPage);
    return updatedLandingPage;
  }
}
