import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  LandingPageBlock,
  LandingPageBlockType,
} from "../../../features/landing-page/domain/models/landing-page.block";
import {
  IdentifiersPerSectionId,
  LandingPageService,
} from "../../services/landing-page.service";

export enum LandingPagePublishErrorDialogAction {
  Publish = "PUBLISH",
}

export type LandingPagePublishErrorDialogData = Record<string, string[]>;

@Component({
  selector: "app-landing-page-publish-error-dialog",
  templateUrl: "./landing-page-publish-error-dialog.component.html",
  styleUrls: ["./landing-page-publish-error-dialog.component.scss"],
})
export class LandingPagePublishErrorDialogComponent implements OnInit {
  public readonly errorsByBlockId: Record<number, string[]> = {};
  public blocks: LandingPageBlock[] = [];
  public identifiersPerSectionId: IdentifiersPerSectionId = {};

  constructor(
    protected readonly dialogRef: MatDialogRef<LandingPagePublishErrorDialogComponent>,
    protected readonly landingPageService: LandingPageService,
    @Inject(MAT_DIALOG_DATA)
    public data: LandingPagePublishErrorDialogData,
  ) {}

  public async ngOnInit(): Promise<void> {
    const blocks = await this.landingPageService.getCurrentLandingPageBlocks();
    this.blocks = blocks.sort((b1, b2) => b1.order - b2.order);
    this.identifiersPerSectionId =
      this.landingPageService.getIdentifiersPerSectionId(this.blocks);
    Object.keys(this.data).forEach(
      (blockId) => (this.errorsByBlockId[+blockId] = this.data[+blockId]),
    );
  }

  public get thereAreErrors(): boolean {
    return !!Object.keys(this.errorsByBlockId).length;
  }

  public publishAnyway(): void {
    this.dialogRef.close(LandingPagePublishErrorDialogAction.Publish);
  }

  public fixBlock(block: number): void {
    this.dialogRef.close(block);
  }

  public getErrorKey(block: string, error: string) {
    return `landingPage.publishErrorDialog.${block}.errors.${error}`;
  }

  public getBlockType(id: number): LandingPageBlockType | undefined {
    return this.blocks.find((block) => block.id === +id)?.type;
  }
}
