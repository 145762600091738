import {
  DeleteNetworkQuery,
  GetNetworkQuery,
  HttpParameters,
  PostNetworkQuery,
  PutNetworkQuery,
} from "../../../../../harmony/core";
import { ApiEndpoint } from "../../../api-endpoint";

export class GetLandingPageBlockQuery extends GetNetworkQuery {
  constructor(
    private readonly landingPageId: number,
    private readonly blockId: number,
  ) {
    super(ApiEndpoint.LandingPageBlock);
  }

  public get queryParameters(): HttpParameters {
    return {
      landingPageId: this.landingPageId,
      blockId: this.blockId,
    };
  }
}

export class DeleteLandingPageBlockQuery extends DeleteNetworkQuery {
  constructor(private readonly blockId: number) {
    super(ApiEndpoint.LandingPageBlock);
  }

  public get queryParameters(): HttpParameters {
    return {
      blockId: this.blockId,
    };
  }
}

export class GetAllLandingPageBlockQuery extends GetNetworkQuery {
  constructor(private readonly landingPageId: number) {
    super(ApiEndpoint.LandingPageBlockAll);
  }

  public get queryParameters(): HttpParameters {
    return {
      landingPageId: this.landingPageId,
    };
  }
}

export class CreateLandingPageBlockQuery extends PostNetworkQuery {
  constructor(
    private readonly landingPageId: number,
    private readonly type: string,
    private readonly order: number,
    private readonly data: unknown = {},
  ) {
    super(ApiEndpoint.LandingPageBlock);
  }

  public get body(): Record<string, unknown> {
    return {
      landingPageId: this.landingPageId,
      type: this.type,
      order: this.order,
      data: this.data,
    };
  }
}

export class UpdateLandingPageBlockQuery extends PutNetworkQuery {
  constructor() {
    super(ApiEndpoint.LandingPageBlock);
  }
}

export class UpdateAllLandingPageBlockQuery extends PutNetworkQuery {
  constructor() {
    super(ApiEndpoint.LandingPageBlockAll);
  }
}
