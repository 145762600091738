import { GetInteractor } from "../../../../../harmony/core";
import { LandingPageService } from "../../../../landing-page/services/landing-page.service";
import { GetLandingPageQuery } from "../../data/queries/landing-page-network.query";
import { LandingPage } from "../models/landing.page";

export class GetLandingPageInteractor {
  constructor(
    private readonly getLandingPage: GetInteractor<LandingPage>,
    private readonly landingPageService: LandingPageService,
  ) {}

  public async execute(landingPageId: number): Promise<LandingPage> {
    const landingPage = await this.getLandingPage.execute(
      new GetLandingPageQuery(landingPageId),
    );
    this.landingPageService.setCurrentLandingPage(landingPage);
    return landingPage;
  }
}
