import { PutInteractor } from "../../../../../harmony/core";
import { BrandService } from "../../../../shared/services/api/brand.service";
import { CreateLandingPageQuery } from "../../data/queries/landing-page-network.query";
import { LandingPage } from "../models/landing.page";

export class CreateLandingPageInteractor {
  constructor(
    private readonly createLandingPage: PutInteractor<LandingPage>,
    private readonly brandService: BrandService,
  ) {}

  public async execute(title: string): Promise<LandingPage> {
    const brandId = this.brandService.currentBrandId;
    return this.createLandingPage.execute(
      undefined,
      new CreateLandingPageQuery(title, brandId!),
    );
  }
}
