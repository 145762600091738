import { mapValueToEnumKey } from "../../../../shared/functions/string-to-enum.mapper";
import { LandingPageEntity } from "../entities/landing-page.entity";
import {
  LandingPage,
  LandingPageStatus,
} from "../../domain/models/landing.page";

export class LandingPageEntityToLandingPageMapper {
  public map(from: LandingPageEntity): LandingPage {
    return new LandingPage(
      from.id,
      from.title,
      from.uuid,
      from.brandId,
      mapValueToEnumKey(LandingPageStatus, from.status),
      from.associatedCampaigns,
      from.uri,
    );
  }
}

export class LandingPageToLandingPageEntityMapper {
  public map(from: LandingPage): LandingPageEntity {
    return new LandingPageEntity(
      from.id,
      from.title,
      from.uuid,
      from.brandId,
      from.status,
      from.associatedCampaigns,
      from.uri,
    );
  }
}
