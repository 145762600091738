import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LandingPageBlock } from "../../features/landing-page/domain/models/landing-page.block";
import { LandingPage } from "../../features/landing-page/domain/models/landing.page";
import { take } from "rxjs/operators";
import { BehaviorSubject, lastValueFrom } from "rxjs";

export type IdentifiersPerSectionId = Record<number, string>;

@Injectable()
export class LandingPageService {
  public currentLandingPage$: BehaviorSubject<LandingPage | undefined> =
    new BehaviorSubject<LandingPage | undefined>(undefined);

  public currentLandingPageBlocks$: BehaviorSubject<LandingPageBlock[]> =
    new BehaviorSubject<LandingPageBlock[]>([]);

  constructor(protected readonly translateService: TranslateService) {}

  public async getCurrentLandingPageBlocks(): Promise<LandingPageBlock[]> {
    return lastValueFrom(this.currentLandingPageBlocks$.pipe(take(1)));
  }

  public setCurrentLandingPage(landingPage: LandingPage): void {
    this.currentLandingPage$.next(landingPage);
  }

  public async setCurrentLandingPageBlocks(
    blocks: LandingPageBlock[],
  ): Promise<void> {
    this.currentLandingPageBlocks$.next(
      blocks.sort((b1, b2) => b1.order - b2.order),
    );
  }

  public async updateCurrentLandingPageBlocks(
    updatedBlocks: LandingPageBlock[],
  ): Promise<void> {
    const blocks = await this.getCurrentLandingPageBlocks();
    const finalBlocks = blocks.map((block) => {
      for (const updatedBlock of updatedBlocks) {
        if (block.id === updatedBlock.id) {
          return updatedBlock;
        }
      }
      return block;
    });
    this.setCurrentLandingPageBlocks(finalBlocks);
  }

  public clearCurrentLandingPage(): void {
    this.currentLandingPage$.next(undefined);
    this.currentLandingPageBlocks$.next([]);
  }

  public async addNewBlock(block: LandingPageBlock): Promise<void> {
    const blocks = await this.getCurrentLandingPageBlocks();
    const newBlocks = [...blocks, block];
    this.setCurrentLandingPageBlocks(newBlocks);
  }

  public async deleteBlock(blockId: number): Promise<void> {
    const blocks = await this.getCurrentLandingPageBlocks();
    this.setCurrentLandingPageBlocks(
      blocks.filter((block) => block.id !== blockId),
    );
  }

  public getIdentifiersPerSectionId(
    sections: LandingPageBlock[],
  ): IdentifiersPerSectionId {
    const identifiers: IdentifiersPerSectionId = {};
    sections.forEach((section) => {
      const identifier = this.getIdentifierBySection(section, sections);
      if (identifier) {
        identifiers[section.id] = identifier;
      }
    });
    return identifiers;
  }

  protected getIdentifierBySection(
    section: LandingPageBlock,
    sections: LandingPageBlock[],
  ): string | undefined {
    let sectionPosition = 1;
    let numberOfSectionsWithSameType = 0;
    let found = false;
    for (const sect of sections) {
      if (sect.id === section.id) {
        found = true;
      }
      if (sect.type === section.type) {
        if (!found) {
          sectionPosition += 1;
        }
        numberOfSectionsWithSameType += 1;
      }
    }

    if (numberOfSectionsWithSameType === 1) {
      return undefined;
    }
    return `#${sectionPosition}`;
  }
}
