import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DeleteFileInteractor } from "../../../features/file/domain/interactors/delete-file.interactor";
import { UpdateLandingPageBlockInteractor } from "../../../features/landing-page/domain/interactors/update-landing-page-block.interactor";
import {
  LandingPageBlockData,
  LandingPageBlock,
  LandingPageBlockType,
} from "../../../features/landing-page/domain/models/landing-page.block";
import { cloneWith } from "../../../shared/services/clonable";
import { LandingPageService } from "../../services/landing-page.service";
import { LandingPageBaseBlockView } from "./landing-page-base-block.component";

export interface LandingPageBaseBlockPresenter {
  attachView(view: LandingPageBaseBlockView): void;
  onEventNgOnInit(block: LandingPageBlock): void;
  onEventSectionSelected(block: LandingPageBlock): void;
  onEventNgOnDestroy(): void;
  onActionEditForm(formValues: Record<string, unknown>): void;
  onActionDeleteImage(url: string): Promise<void>;
  onActionSaveForm(formValues: Record<string, unknown>): void;
}

export class DefaultLandingPageBaseBlockPresenter
  implements LandingPageBaseBlockPresenter
{
  private view!: LandingPageBaseBlockView;
  private subscription!: Subscription;
  private landingPageId!: number;

  constructor(
    protected readonly landingPageService: LandingPageService,
    protected readonly updateBlock: UpdateLandingPageBlockInteractor,
    protected readonly deleteFile: DeleteFileInteractor,
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
  ) {}

  public attachView(view: LandingPageBaseBlockView): void {
    this.view = view;
  }

  public async onEventNgOnInit(): Promise<void> {
    this.onSectionSelected();
  }

  public async onEventSectionSelected(): Promise<void> {
    this.onSectionSelected();
  }

  protected async onSectionSelected(): Promise<void> {
    this.view.onDisplayPlaceholder(true);

    this.subscription = new Subscription();

    this.subscription.add(
      this.landingPageService.currentLandingPage$.subscribe((landingPage) => {
        if (!landingPage) {
          return;
        }

        this.landingPageId = landingPage.id;
        this.view.setLandingPageId(this.landingPageId);

        this.view.setForm();
        this.view.onDisplayPlaceholder(false);
      }),
    );
  }

  public async onActionEditForm(
    formValues: Record<string, unknown>,
  ): Promise<void> {
    return this.view.onDisplaySection(
      this.generateBlockFromFormValue(formValues) as LandingPageBlock,
    );
  }

  public async onActionSaveForm(
    formValues: Record<string, unknown>,
  ): Promise<void> {
    this.view.onDisplaySaving(true);
    try {
      const updatedSection = this.generateBlockFromFormValue(
        formValues,
      ) as LandingPageBlock;

      await this.updateBlock.execute(updatedSection);

      this.view.onDisplaySaving(false);
    } catch (error: unknown) {
      if (error instanceof HttpErrorResponse) {
        this.view.onDisplaySavingError();
        return;
      }
      throw error;
    }
  }

  protected generateBlockFromFormValue(
    formValues: Record<string, unknown>,
  ): Partial<LandingPageBlock> {
    const sectionData = cloneWith(formValues, {});
    delete sectionData.id;
    delete sectionData.type;
    delete sectionData.order;
    return new LandingPageBlock(
      Number(formValues.id),
      this.landingPageId,
      formValues.type as LandingPageBlockType,
      Number(formValues.order),
      sectionData as unknown as LandingPageBlockData,
    );
  }

  public onEventNgOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async onActionDeleteImage(url: string): Promise<void> {
    await this.deleteFile.execute(url);
  }
}
