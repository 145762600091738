import { GetInteractor } from "../../../../../harmony/core";
import { GetLandingPageBlockQuery } from "../../data/queries/landing-page-block-network.query";
import { LandingPageBlock } from "../models/landing-page.block";

export class GetLandingPageBlockInteractor {
  constructor(private readonly getBlock: GetInteractor<LandingPageBlock>) {}

  public async execute(
    landingPageId: number,
    blockId: number,
  ): Promise<LandingPageBlock | undefined> {
    return this.getBlock
      .execute(new GetLandingPageBlockQuery(landingPageId, blockId))
      .catch(() => undefined);
  }
}
