import {
  GetNetworkQuery,
  HttpBody,
  HttpMethod,
  HttpParameters,
  NetworkQuery,
  PostNetworkQuery,
  PutNetworkQuery,
} from "../../../../../harmony/core";
import { ApiEndpoint } from "../../../api-endpoint";

abstract class LandingPageQuery extends NetworkQuery {
  constructor(public readonly id: number) {
    super(ApiEndpoint.LandingPage, id);
  }

  public get queryParameters(): HttpParameters {
    return {
      landingPageId: this.id,
    };
  }
}

export class GetLandingPageQuery extends LandingPageQuery {
  public get method(): HttpMethod {
    return HttpMethod.Get;
  }
}

export class DeleteLandingPageQuery extends LandingPageQuery {
  public get method(): HttpMethod {
    return HttpMethod.Delete;
  }
}

export class GetAllLandingPageQuery extends GetNetworkQuery {
  constructor(
    private readonly offset: number,
    private readonly limit: number,
    private readonly brandId: number,
    private readonly searchFilter?: string,
    private readonly sortMode?: string,
  ) {
    super(ApiEndpoint.LandingPageAll);
  }

  public get queryParameters(): HttpParameters {
    return {
      brandId: this.brandId,
      offset: this.offset,
      limit: this.limit,
      ...(this.searchFilter && { searchFilter: this.searchFilter }),
      ...(this.sortMode && { sortMode: this.sortMode }),
    };
  }
}

export class CreateLandingPageQuery extends PostNetworkQuery {
  constructor(
    private readonly title: string,
    private readonly brandId: number,
  ) {
    super(ApiEndpoint.LandingPage);
  }

  public get body(): HttpBody {
    return {
      brandId: this.brandId,
      title: this.title,
    };
  }
}

export class PublishLandingPageQuery extends PutNetworkQuery {
  constructor(
    private readonly id: number,
    private readonly forcePublish: boolean,
  ) {
    super(ApiEndpoint.LandingPagePublish);
  }

  public get body(): HttpBody {
    return {
      landingPageId: this.id,
      forcePublish: this.forcePublish,
    };
  }
}

export class UnpublishLandingPageQuery extends PutNetworkQuery {
  constructor(private readonly id: number) {
    super(ApiEndpoint.LandingPageUnpublish);
  }

  public get body(): HttpBody {
    return {
      landingPageId: this.id,
    };
  }
}

export class UpdateLandingPageQuery extends PutNetworkQuery {
  constructor(
    private readonly id: number,
    private readonly title: string,
  ) {
    super(ApiEndpoint.LandingPage);
  }

  public get body(): HttpBody {
    return {
      landingPageId: this.id,
      ...(this?.title && { title: this?.title }),
    };
  }
}
