import { FontEntity } from "./font.entity";
import { ColorPaletteEntity } from "./color-palette.entity";

export class LandingPageBlockEntity {
  constructor(
    public readonly id: number,
    public readonly landingPageId: number,
    public readonly type: string,
    public readonly order = 0,
    public readonly data: LandingPageBlockDataEntity,
  ) {}
}

export class LandingPageBlockDataEntity {}

export class LandingPageLogoBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly backgroundColor: string | undefined,
    public readonly logoUrl: string | undefined,
  ) {
    super();
  }
}

export class LandingPageStyleBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly colorPalette: ColorPaletteEntity,
    public readonly font: FontEntity,
  ) {
    super();
  }
}

export class LandingPageFooterBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly backgroundColor: string | undefined,
    public readonly textColor: string | undefined,
    public readonly imprintUrl: string | undefined,
    public readonly dataProtectionUrl: string | undefined,
  ) {
    super();
  }
}

export class LandingPageHeroBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly backgroundImageUrl: string | undefined,
    public readonly mobileBackgroundImageUrl: string | undefined,
    public readonly blockStyle: number | undefined,
    public readonly textColor: string | undefined,
    public readonly mainText: string | undefined,
    public readonly secondaryText: string | undefined,
    public readonly callToActionText: string | undefined,
    public readonly buttonLink: string | undefined,
    public readonly horizontalTextAlignment: string | undefined,
    public readonly verticalTextAlignment: string | undefined,
  ) {
    super();
  }
}

export class LandingPageMapBlockEntity extends LandingPageBlockDataEntity {
  constructor(public readonly title: string | undefined) {
    super();
  }
}

export class LandingPageRetailersBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly title: string | undefined,
    public readonly forwardToRetailersPage: boolean | undefined,
  ) {
    super();
  }
}

export class LandingPageImageTextBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly imageUrl: string | undefined,
    public readonly title: string | undefined,
    public readonly text: string | undefined,
  ) {
    super();
  }
}

export class LandingPageCarouselBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly imageUrls: string[] | undefined,
    public readonly title: string | undefined,
    public readonly text: string | undefined,
    public readonly autoScroll: boolean = true,
  ) {
    super();
  }
}

export class LandingPageConfigBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly language: string,
    public readonly faviconUrl: string | undefined,
  ) {
    super();
  }
}

export class LandingPageSocialMediaBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly title: string | undefined,
    public readonly description: string | undefined,
    public readonly fbImage: string | undefined,
  ) {
    super();
  }
}

export class LandingPageAnalyticsBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly gTagManagerId: string | undefined,
    public readonly gAnalyticsId: string | undefined,
  ) {
    super();
  }
}

export class LandingPageVideoBlockEntity extends LandingPageBlockDataEntity {
  constructor(
    public readonly title: string | undefined,
    public readonly text: string | undefined,
    public readonly videoUrl: string | undefined,
  ) {
    super();
  }
}
