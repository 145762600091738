import { Font } from "./font";
import { ColorPalette } from "./color-palette";

export enum LandingPageBlockType {
  Analytics = "analytics",
  Carousel = "carousel",
  Config = "config",
  Footer = "footer",
  Hero = "hero",
  ImageText = "image-text",
  Logo = "logo",
  Map = "map",
  Retailers = "retailers",
  SocialMedia = "social-media",
  Style = "style",
  Video = "video",
}

const LANDING_PAGE_SECTIONS = [
  LandingPageBlockType.Hero,
  LandingPageBlockType.Retailers,
  LandingPageBlockType.ImageText,
  LandingPageBlockType.Carousel,
  LandingPageBlockType.Map,
  LandingPageBlockType.Video,
];

const SORTABLE_LANDING_PAGE_SECTIONS = [
  LandingPageBlockType.Retailers,
  LandingPageBlockType.ImageText,
  LandingPageBlockType.Carousel,
  LandingPageBlockType.Map,
  LandingPageBlockType.Video,
];

const DELETABLE_LANDING_PAGE_SECTIONS = [
  LandingPageBlockType.ImageText,
  LandingPageBlockType.Carousel,
  LandingPageBlockType.Video,
];

export type LandingPageBlockData =
  | LandingPageAnalyticsBlock
  | LandingPageCarouselBlock
  | LandingPageConfigBlock
  | LandingPageFooterBlock
  | LandingPageHeroBlock
  | LandingPageImageTextBlock
  | LandingPageLogoBlock
  | LandingPageMapBlock
  | LandingPageRetailersBlock
  | LandingPageSocialMediaBlock
  | LandingPageStyleBlock
  | LandingPageVideoBlock;

export class LandingPageBlock {
  constructor(
    public readonly id: number,
    public readonly landingPageId: number,
    public readonly type: LandingPageBlockType,
    public readonly order = 0,
    public readonly data: LandingPageBlockData,
  ) {}

  public get isSortable(): boolean {
    return SORTABLE_LANDING_PAGE_SECTIONS.includes(this.type);
  }

  public get isDeletable(): boolean {
    return DELETABLE_LANDING_PAGE_SECTIONS.includes(this.type);
  }

  public get isSection(): boolean {
    return LANDING_PAGE_SECTIONS.includes(this.type);
  }
}

export class LandingPageLogoBlock {
  constructor(
    public readonly backgroundColor: string | undefined,
    public readonly logoUrl: string | undefined,
  ) {}

  public static isEmpty(block: LandingPageLogoBlock): boolean {
    return !block.backgroundColor && !block.logoUrl;
  }
}

export class LandingPageStyleBlock {
  constructor(
    public readonly colorPalette: ColorPalette,
    public readonly font: Font,
  ) {}

  public static isEmpty(block: LandingPageStyleBlock): boolean {
    return !block.colorPalette && !block.font;
  }
}

export class LandingPageFooterBlock {
  constructor(
    public readonly backgroundColor: string | undefined,
    public readonly textColor: string | undefined,
    public readonly imprintUrl: string | undefined,
    public readonly dataProtectionUrl: string | undefined,
  ) {}

  public static isEmpty(block: LandingPageFooterBlock): boolean {
    return (
      !block.backgroundColor &&
      !block.textColor &&
      !block.imprintUrl &&
      !block.dataProtectionUrl
    );
  }
}

export enum LandingPageHeroBlockStyle {
  Default,
  FullSizeImage,
}

export class LandingPageHeroBlock {
  constructor(
    public readonly backgroundImageUrl: string | undefined,
    public readonly mobileBackgroundImageUrl: string | undefined,
    public readonly blockStyle: LandingPageHeroBlockStyle | undefined,
    public readonly textColor: string | undefined,
    public readonly mainText: string | undefined,
    public readonly secondaryText: string | undefined,
    public readonly callToActionText: string | undefined,
    public readonly buttonLink: string | undefined,
    public readonly horizontalTextAlignment: string | undefined,
    public readonly verticalTextAlignment: string | undefined,
  ) {}

  public static isEmpty(block: LandingPageHeroBlock): boolean {
    return (
      !block.backgroundImageUrl &&
      !block.mobileBackgroundImageUrl &&
      !block.mainText &&
      !block.secondaryText &&
      !block.callToActionText
    );
  }
}

export class LandingPageMapBlock {
  constructor(public readonly title?: string) {}

  public static isEmpty(block: LandingPageMapBlock): boolean {
    return !block.title;
  }
}

export class LandingPageRetailersBlock {
  constructor(
    public readonly title: string | undefined,
    public readonly forwardToRetailersPage: boolean | undefined,
  ) {}

  public static isEmpty(block: LandingPageRetailersBlock): boolean {
    return !block.title;
  }
}

export class LandingPageImageTextBlock {
  constructor(
    public readonly imageUrl: string | undefined,
    public readonly title: string | undefined,
    public readonly text: string | undefined,
  ) {}

  public static isEmpty(block: LandingPageImageTextBlock): boolean {
    return !block.imageUrl && !block.title && !block.text;
  }
}

export class LandingPageCarouselBlock {
  constructor(
    public readonly imageUrls: string[] | undefined,
    public readonly title: string | undefined,
    public readonly text: string | undefined,
    public readonly autoScroll: boolean | undefined,
  ) {}

  public static isEmpty(block: LandingPageCarouselBlock): boolean {
    return !block.imageUrls?.length && !block.title && !block.text;
  }
}

export class LandingPageConfigBlock {
  constructor(
    public readonly language: string,
    public readonly faviconUrl: string | undefined,
  ) {}

  public static isEmpty(block: LandingPageConfigBlock): boolean {
    return !block.language && !block.faviconUrl;
  }
}

export class LandingPageSocialMediaBlock {
  constructor(
    public readonly title: string | undefined,
    public readonly description: string | undefined,
    public readonly fbImage: string | undefined,
  ) {}

  public static isEmpty(block: LandingPageSocialMediaBlock): boolean {
    return !block.title && !block.description && !block.fbImage;
  }
}

export class LandingPageAnalyticsBlock {
  constructor(
    public readonly gTagManagerId: string | undefined,
    public readonly gAnalyticsId: string | undefined,
  ) {}

  public static isEmpty(block: LandingPageAnalyticsBlock): boolean {
    return !block.gTagManagerId && !block.gAnalyticsId;
  }
}

export class LandingPageVideoBlock {
  constructor(
    public readonly title: string | undefined,
    public readonly text: string | undefined,
    public readonly videoUrl: string | undefined,
  ) {}

  public static isEmpty(block: LandingPageVideoBlock): boolean {
    return !block.title && !block.text && !block.videoUrl;
  }
}
