<span class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title>
  {{ "landingPage.publishErrorDialog.title" | translate }}
</h2>
<mat-dialog-content>
  <p class="description">
    {{ "landingPage.publishErrorDialog.description" | translate }}
  </p>

  <ng-container *ngIf="thereAreErrors">
    <ng-container *ngFor="let block of blocks">
      <div class="block" *ngIf="!!errorsByBlockId[block.id]">
        <p class="block-errors">
          <span class="block-errors__title"
            >{{
              "landingPage.navigation." +
                (block.type | caseTransform: "KebabToCamel") | translate
            }}<ng-container *ngIf="identifiersPerSectionId[block.id]">
              {{ identifiersPerSectionId[block.id] }}</ng-container
            >:
          </span>
          <span
            class="block-errors__error"
            *ngFor="let error of errorsByBlockId[block.id]; let i = index"
          >
            {{ getErrorKey(getBlockType(block.id), error) | translate
            }}{{ i < errorsByBlockId[block.id].length - 1 ? ", " : "" }}
          </span>
        </p>
        <div class="block__action">
          <button
            class="btn btn--transparent btn--square"
            (click)="fixBlock(block.id)"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" (click)="publishAnyway()">
    {{ "landingPage.publishErrorDialog.action" | translate }}
  </button>
  <button class="btn btn--primary" [mat-dialog-close]="false">
    {{ "shared.cancel" | translate }}
  </button>
</mat-dialog-actions>
