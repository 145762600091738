import { PutInteractor } from "../../../../../harmony/core";
import { LandingPageService } from "../../../../landing-page/services/landing-page.service";
import { UpdateLandingPageBlockQuery } from "../../data/queries/landing-page-block-network.query";
import { LandingPageBlock } from "../models/landing-page.block";

export class UpdateLandingPageBlockInteractor {
  constructor(
    protected readonly updateBlock: PutInteractor<LandingPageBlock>,
    protected readonly landingPageService: LandingPageService,
  ) {}

  public async execute(
    landingPageBlock: LandingPageBlock,
  ): Promise<LandingPageBlock> {
    const updatedBlock = await this.updateBlock.execute(
      landingPageBlock,
      new UpdateLandingPageBlockQuery(),
    );

    this.landingPageService.updateCurrentLandingPageBlocks([landingPageBlock]);

    return updatedBlock;
  }
}
