import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { PublishLandingPageInteractor } from "../../../features/landing-page/domain/interactors/publish-landing-page.interactor";
import { UnpublishLandingPageInteractor } from "../../../features/landing-page/domain/interactors/unpublish-landing-page.interactor";
import { UpdateLandingPageInteractor } from "../../../features/landing-page/domain/interactors/update-landing-page.interactor";
import { LandingPage } from "../../../features/landing-page/domain/models/landing.page";
import { NotificationService } from "../../../shared/services/notification.service";
import { RouterService } from "../../../shared/services/router.service";
import { LandingPageService } from "../../services/landing-page.service";
import {
  LandingPageFormDialogComponent,
  LandingPageFormDialogData,
} from "../landing-page-form-dialog/landing-page-form-dialog.component";
import {
  LandingPagePublishErrorDialogAction,
  LandingPagePublishErrorDialogComponent,
  LandingPagePublishErrorDialogData,
} from "../landing-page-publish-error-dialog/landing-page-publish-error-dialog.component";
import { LandingPageBuilderHeaderView } from "./landing-page-builder-header.component";

export interface LandingPageBuilderHeaderPresenter {
  attachView(view: LandingPageBuilderHeaderView): void;
  onEventNgOnInit(): void;
  onEventNgOnDestroy(): void;
  onActionEditTitle(): void;
  onActionPreview(landingPageId: number): void;
  onActionPublish(): void;
  onActionUnpublish(): void;
  onActionUnpublishConfirmed(): void;
}

export class DefaultLandingPageBuilderHeaderPresenter
  implements LandingPageBuilderHeaderPresenter
{
  private landingPage?: LandingPage;
  private readonly subscription = new Subscription();
  private view!: LandingPageBuilderHeaderView;

  constructor(
    protected readonly publishLandingPage: PublishLandingPageInteractor,
    protected readonly unpublishLandingPage: UnpublishLandingPageInteractor,
    protected readonly landingPageService: LandingPageService,
    protected readonly updateLandingPage: UpdateLandingPageInteractor,
    protected readonly notificationService: NotificationService,
    protected readonly dialog: MatDialog,
    protected readonly router: RouterService,
  ) {}

  public attachView(view: LandingPageBuilderHeaderView): void {
    this.view = view;
  }

  public onEventNgOnInit(): void {
    this.subscription.add(
      this.landingPageService.currentLandingPage$.subscribe((landingPage) => {
        this.landingPage = landingPage;
        this.view.onDisplayLandingPage(landingPage);
      }),
    );
  }

  public onEventNgOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async onActionEditTitle(): Promise<void> {
    const data: LandingPageFormDialogData = {
      landingPageTitle: this.landingPage?.title,
    };

    const dialogRef = await this.dialog.open(LandingPageFormDialogComponent, {
      width: "512px",
      data: data,
    });

    const subscription = dialogRef.componentInstance.onSave.subscribe(
      (landingPageTitle) => {
        if (landingPageTitle) {
          const editedLandingPage = {
            ...this.landingPage,
            title: landingPageTitle,
          } as LandingPage;

          this.view.onDisplaySaving(true);
          try {
            this.updateLandingPage.execute(editedLandingPage).then(() => {
              this.notificationService.success(
                "landingPage.landingPageUpdated",
              );
            });
          } catch (e) {
            this.notificationService.error("shared.errorPerformingTheAction");
          } finally {
            this.view.onDisplaySaving(false);
          }
        }
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      subscription?.unsubscribe();
    });
  }

  public onActionPreview(): void {
    window.open(
      `${environment.api.baseUrl}${environment.api.apiPathV2}landing-page/preview/${this.landingPage?.uuid}`,
      "_blank",
    );
  }

  public async onActionPublish(forcePublish = false): Promise<void> {
    if (!this.landingPage?.id) {
      return;
    }

    try {
      this.landingPage = await this.publishLandingPage.execute(
        this.landingPage!.id,
        forcePublish,
      );
      this.view.onDisplayLandingPage(this.landingPage);
      this.notificationService.success("landingPage.landingPagePublished");
    } catch (error: unknown) {
      if (error instanceof HttpErrorResponse) {
        this.handlePublishError(error);
      }
    }
  }

  public onActionUnpublish(): void {
    if (!this.landingPage?.id) {
      return;
    }

    this.view.onDisplayUnpublishLandingPageConfirmation();
  }

  public async onActionUnpublishConfirmed(): Promise<void> {
    this.landingPage = await this.unpublishLandingPage.execute(
      this.landingPage!.id,
    );
    this.view.onDisplayLandingPage(this.landingPage);
    this.notificationService.success("landingPage.landingPageUnpublished");
  }

  private async handlePublishError(error: HttpErrorResponse): Promise<void> {
    const data: LandingPagePublishErrorDialogData = { ...error.error };
    const dialogRef = await this.dialog.open(
      LandingPagePublishErrorDialogComponent,
      {
        width: "616px",
        data: data,
      },
    );

    dialogRef
      .afterClosed()
      .subscribe(
        (actionOrBlockId: LandingPagePublishErrorDialogAction | number) => {
          if (!actionOrBlockId) {
            return;
          }
          switch (actionOrBlockId) {
            case LandingPagePublishErrorDialogAction.Publish:
              this.onActionPublish(true);
              break;
            default:
              this.router.navigateByUrl(
                `brand/landing-page/${this.landingPage?.id}/edit/${actionOrBlockId}`,
              );
              break;
          }
        },
      );
  }
}
