import { PutInteractor } from "../../../../../harmony/core";
import { LandingPageService } from "../../../../landing-page/services/landing-page.service";
import { CreateLandingPageBlockQuery } from "../../data/queries/landing-page-block-network.query";
import {
  LandingPageBlock,
  LandingPageBlockType,
} from "../models/landing-page.block";

export class CreateLandingPageBlockInteractor {
  constructor(
    private readonly createBlock: PutInteractor<LandingPageBlock>,
    private readonly landingPageService: LandingPageService,
  ) {}

  public async execute(
    landingPageId: number,
    type: LandingPageBlockType,
    order: number,
  ): Promise<LandingPageBlock> {
    const createdBlock = await this.createBlock.execute(
      undefined,
      new CreateLandingPageBlockQuery(landingPageId, type, order),
    );

    await this.landingPageService.addNewBlock(createdBlock);
    return createdBlock;
  }
}
