export class ColorPalette {
  constructor(
    public backgroundColor: string,
    public textColor: string,
    public buttonColor: string,
  ) {}
}

export const DEFAULT_PICKER_COLORS: string[] = [
  "#000000",
  "#9a9a9a",
  "#ffffff",
  "#f28a3d",
  "#4a90e2",
  "#1dbf77",
  "#d0021b",
];

export const DEFAULT_PICKER_PALETTES: ColorPalette[] = [
  new ColorPalette("#FAF7EB", "#000000", "#FF7701"),
  new ColorPalette("#FDC3D5", "#000000", "#FF0B55"),
  new ColorPalette("#FF9534", "#000000", "#FAF7EB"),
  new ColorPalette("#124E67", "#FFFFFF", "#000000"),
  new ColorPalette("#0a0a0a", "#FFFFFF", "#FF7701"),
];
