import { PutInteractor } from "../../../../../harmony/core";
import { PublishLandingPageQuery } from "../../data/queries/landing-page-network.query";
import { LandingPage } from "../models/landing.page";

export class PublishLandingPageInteractor {
  constructor(private readonly putLandingPage: PutInteractor<LandingPage>) {}

  public async execute(
    landingPageId: number,
    forcePublish: boolean,
  ): Promise<LandingPage> {
    return this.putLandingPage.execute(
      undefined,
      new PublishLandingPageQuery(landingPageId, forcePublish),
    );
  }
}
