import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DeleteLandingPageBlockInteractor } from "../../../features/landing-page/domain/interactors/delete-landing-page-block.interactor";
import { UpdateAllLandingPageBlocksInteractor } from "../../../features/landing-page/domain/interactors/update-all-landing-page-blocks.interactor";
import { LandingPageBlock } from "../../../features/landing-page/domain/models/landing-page.block";
import { cloneWith } from "../../../shared/services/clonable";
import { LandingPageService } from "../../services/landing-page.service";
import { LandingPageBuilderSidebarView } from "./landing-page-builder-sidebar.component";

export interface LandingPageBuilderSidebarPresenter {
  attachView(view: LandingPageBuilderSidebarView): void;
  onActionAddNewSection(): void;
  onActionDeleteSection(section: LandingPageBlock): void;
  onActionDeleteSectionConfirmed(section: LandingPageBlock): void;
  onActionSectionCreated(newSection: LandingPageBlock): void;
  onActionSectionsOrderChanged(event: CdkDragDrop<unknown[]>): void;
  onEventNgOnChanges(landingPageBlocks: LandingPageBlock[]): void;
  onEventNgOnInit(
    landingPageBlocks: LandingPageBlock[],
    currentSectionId: number,
  ): void;
}

@Injectable()
export class DefaultLandingPageBuilderSidebarPresenter
  implements LandingPageBuilderSidebarPresenter
{
  protected view!: LandingPageBuilderSidebarView;
  protected landingPageBlocks: LandingPageBlock[] = [];
  protected sortableSections: LandingPageBlock[] = [];
  protected firstBlockOrder!: number;
  protected currentSectionId!: number;

  constructor(
    protected readonly updateAllBlocks: UpdateAllLandingPageBlocksInteractor,
    protected readonly router: Router,
    protected readonly deleteSection: DeleteLandingPageBlockInteractor,
    protected readonly landingPageService: LandingPageService,
  ) {}

  public onEventNgOnInit(
    landingPageBlocks: LandingPageBlock[],
    currentSectionId: number,
  ): void {
    this.landingPageBlocks = landingPageBlocks;
    this.currentSectionId = currentSectionId;
  }

  public async onEventNgOnChanges(
    landingPageBlocks: LandingPageBlock[],
  ): Promise<void> {
    if (landingPageBlocks.length) {
      this.landingPageBlocks = landingPageBlocks;

      const fixedSections = this.landingPageBlocks.filter(
        (block) => block.isSection && !block.isSortable,
      );
      this.sortableSections = this.landingPageBlocks.filter(
        (block) => block.isSortable,
      );
      this.firstBlockOrder = this.sortableSections[0].order;

      this.view.onDisplayFixedSections(fixedSections);
      this.view.onDisplaySortableSections(
        this.sortableSections,
        this.landingPageService.getIdentifiersPerSectionId(
          this.sortableSections,
        ),
      );
    }
  }

  public attachView(view: LandingPageBuilderSidebarView): void {
    this.view = view;
  }

  public onActionAddNewSection(): void {
    this.view.onDisplayNewSectionDialog();
  }

  public onActionDeleteSection(section: LandingPageBlock): void {
    this.view.onDisplayDeleteSectionConfirmation(section);
  }

  public async onActionDeleteSectionConfirmed(
    section: LandingPageBlock,
  ): Promise<void> {
    await this.deleteSection.execute(section.id);
  }

  public onActionSectionCreated(section: LandingPageBlock): void {
    if (section) {
      this.router.navigate([
        `/brand/landing-page/${section.landingPageId}/edit/${section.id}`,
      ]);
    }
  }

  public onActionSectionsOrderChanged(event: CdkDragDrop<unknown[]>): void {
    const movedItem = this.sortableSections.splice(event.previousIndex, 1)[0];
    this.sortableSections.splice(event.currentIndex, 0, movedItem);
    this.updateBlocksOrder();
  }

  protected async updateBlocksOrder(): Promise<void> {
    let currentOrder = this.firstBlockOrder;
    const blocksToUpdate = [];
    for (const block of this.sortableSections) {
      if (block.order !== currentOrder) {
        const updatedBlock = cloneWith(block, { order: currentOrder });
        blocksToUpdate.push(updatedBlock);
      }
      currentOrder++;
    }

    if (blocksToUpdate.length) {
      await this.updateAllBlocks.execute(blocksToUpdate);
    }
  }
}
