export enum LandingPageStatus {
  Draft = "draft",
  DraftPending = "unpublished",
  Published = "published",
}

export class LandingPage {
  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly uuid: string,
    public readonly brandId: number,
    public readonly status: LandingPageStatus,
    public readonly associatedCampaigns?: string[],
    public readonly uri?: string,
  ) {}

  public get isPublished(): boolean {
    return this.status === LandingPageStatus.Published;
  }
}
