import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { PaginationOffsetLimit } from "../../../../harmony/core";
import { CreateLandingPageInteractor } from "../../../features/landing-page/domain/interactors/create-landing-page.interactor";
import { GetLandingPageSummariesInteractor } from "../../../features/landing-page/domain/interactors/get-landing-page-summaries.interactor";
import { LandingPageSummary } from "../../../features/landing-page/domain/models/landing-page.summary";
import { LandingPageStatus } from "../../../features/landing-page/domain/models/landing.page";
import { SortByDateMode } from "../../../shared/enums/campaign.enums";
import { BrandService } from "../../../shared/services/api/brand.service";
import { cloneWith } from "../../../shared/services/clonable";
import { NotificationService } from "../../../shared/services/notification.service";
import { RouterService } from "../../../shared/services/router.service";
import { LandingPageFormDialogComponent } from "../../components/landing-page-form-dialog/landing-page-form-dialog.component";
import { LandingPageListView } from "./landing-page-list.component";
import { BrandNotSelectedError } from "../../../shared/errors/brand-not-selected-error";

export interface LandingPageListPresenter {
  attachView(view: LandingPageListView): void;
  onEventNgOnInit(): void;
  onEventNgOnDestroy(): void;
  onActionFilter(term: string): void;
  onActionSwitchSortMode(): void;
  onActionCreateLandingPage(): void;
  onActionLandingPagesListScrolled(
    filter: string,
    sortMode: SortByDateMode,
  ): void;
  onEventReloadLandingPages(): void;
  onActionLandingDeleted(): void;
  onEventLandingStatusChanged(
    landingPageId: number,
    status: LandingPageStatus,
  ): void;
}

export class DefaultLandingPageListPresenter
  implements LandingPageListPresenter
{
  private landingPages: LandingPageSummary[] = [];
  private allElementsLoaded = false;
  private currentOffset = 0;
  private readonly pageSize = 32;
  private loading = false;
  private searchFilter: string | undefined;
  private sortMode = SortByDateMode.Desc;
  private view!: LandingPageListView;
  private subscription = new Subscription();

  constructor(
    protected readonly brandService: BrandService,
    protected readonly notificationService: NotificationService,
    protected readonly getLandingPages: GetLandingPageSummariesInteractor,
    protected readonly createLandingPage: CreateLandingPageInteractor,
    protected readonly dialogService: MatDialog,
    protected readonly router: RouterService,
  ) {}

  public attachView(view: LandingPageListView): void {
    this.view = view;
  }

  public onEventNgOnInit(): void {
    this.onEventReloadLandingPages();
  }

  public onEventNgOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public async onActionCreateLandingPage(): Promise<void> {
    const dialogRef = await this.dialogService.open(
      LandingPageFormDialogComponent,
      {
        width: "512px",
      },
    );

    const subscription = dialogRef.componentInstance.onSave.subscribe(
      (landingPageTitle) => {
        if (landingPageTitle) {
          this.createLandingPage
            .execute(landingPageTitle)
            .then((landingPage) => {
              this.router.navigateByUrl(`brand/landing-page/${landingPage.id}`);
            });
        }
      },
    );

    dialogRef.afterClosed().subscribe(() => {
      subscription?.unsubscribe();
    });
  }

  public onActionFilter(term: string): void {
    this.searchFilter = term;
    this.onEventReloadLandingPages();
  }

  private async loadLandingPages(): Promise<void> {
    if (this.loading || this.allElementsLoaded) {
      return;
    }

    this.isLoading(true);

    let response: PaginationOffsetLimit<LandingPageSummary>;

    try {
      response = await this.getLandingPages.execute(
        this.currentOffset,
        this.pageSize,
        this.searchFilter,
        this.sortMode,
      );
    } catch (result) {
      if (result instanceof BrandNotSelectedError) {
        throw result;
      }

      this.notificationService.error("landingPage.list.loadingError");
      return;
    }

    this.landingPages = this.landingPages.concat(response.values);
    this.currentOffset += response.values.length;
    this.allElementsLoaded = response.size === this.landingPages.length;
    this.view.onDisplayLandingPages(this.landingPages, this.sortMode);

    this.isLoading(false);
  }

  private isLoading(loading: boolean): void {
    this.loading = loading;
    this.view.onDisplayLoading(loading);
  }

  public onEventReloadLandingPages(): void {
    this.currentOffset = 0;
    this.landingPages = [];
    this.allElementsLoaded = false;
    this.loadLandingPages();
  }

  public async onActionLandingPagesListScrolled(): Promise<void> {
    if (this.landingPages.length) {
      this.loadLandingPages();
    }
  }

  public onActionSwitchSortMode(): void {
    this.sortMode =
      this.sortMode === SortByDateMode.Asc
        ? SortByDateMode.Desc
        : SortByDateMode.Asc;
    this.onEventReloadLandingPages();
  }

  public onActionLandingDeleted(): void {
    this.onEventReloadLandingPages();
  }

  public onEventLandingStatusChanged(
    landingPageId: number,
    status: LandingPageStatus,
  ) {
    this.landingPages = this.landingPages.map((landing) =>
      landing.id !== landingPageId
        ? landing
        : cloneWith(landing, { status: status }),
    );
    this.view.onDisplayLandingPages(this.landingPages, this.sortMode);
  }
}
