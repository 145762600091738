import { NotFoundError } from "../../../../../harmony/core";
import {
  LandingPageAnalyticsBlockEntity,
  LandingPageBlockEntity,
  LandingPageCarouselBlockEntity,
  LandingPageConfigBlockEntity,
  LandingPageFooterBlockEntity,
  LandingPageHeroBlockEntity,
  LandingPageImageTextBlockEntity,
  LandingPageLogoBlockEntity,
  LandingPageMapBlockEntity,
  LandingPageRetailersBlockEntity,
  LandingPageSocialMediaBlockEntity,
  LandingPageStyleBlockEntity,
  LandingPageVideoBlockEntity,
} from "../entities/landing-page-block.entity";
import {
  LandingPageAnalyticsBlock,
  LandingPageBlockData,
  LandingPageBlock,
  LandingPageBlockType,
  LandingPageCarouselBlock,
  LandingPageConfigBlock,
  LandingPageFooterBlock,
  LandingPageHeroBlock,
  LandingPageImageTextBlock,
  LandingPageLogoBlock,
  LandingPageRetailersBlock,
  LandingPageSocialMediaBlock,
  LandingPageStyleBlock,
  LandingPageVideoBlock,
  LandingPageMapBlock,
} from "../../domain/models/landing-page.block";
import {
  ColorPaletteEntityToColorPaletteMapper,
  ColorPaletteToColorPaletteEntityMapper,
} from "./color-palette.mapper";
import { FontEntityToFontMapper, FontToFontEntityMapper } from "./font.mapper";

export class LandingPageBlockEntityToLandingPageBlockMapper {
  constructor(
    private readonly colorPaletteMapper: ColorPaletteEntityToColorPaletteMapper,
    private readonly fontMapper: FontEntityToFontMapper,
  ) {}

  public map(from: LandingPageBlockEntity): LandingPageBlock {
    let blockData: LandingPageBlockData;

    switch (from.type) {
      case LandingPageBlockType.Analytics:
        const analytics = from.data as LandingPageAnalyticsBlockEntity;

        blockData = new LandingPageAnalyticsBlock(
          analytics.gTagManagerId,
          analytics.gAnalyticsId,
        );
        break;

      case LandingPageBlockType.Carousel:
        const carousel = from.data as LandingPageCarouselBlockEntity;

        blockData = new LandingPageCarouselBlock(
          carousel.imageUrls,
          carousel.title,
          carousel.text,
          carousel.autoScroll,
        );
        break;

      case LandingPageBlockType.Config:
        const config = from.data as LandingPageConfigBlockEntity;

        blockData = new LandingPageConfigBlock(
          config.language,
          config.faviconUrl,
        );
        break;

      case LandingPageBlockType.Footer:
        const footer = from.data as LandingPageFooterBlockEntity;

        blockData = new LandingPageFooterBlock(
          footer.backgroundColor,
          footer.textColor,
          footer.imprintUrl,
          footer.dataProtectionUrl,
        );
        break;

      case LandingPageBlockType.Hero:
        const hero = from.data as LandingPageHeroBlockEntity;

        blockData = new LandingPageHeroBlock(
          hero.backgroundImageUrl,
          hero.mobileBackgroundImageUrl,
          hero.blockStyle,
          hero.textColor,
          hero.mainText,
          hero.secondaryText,
          hero.callToActionText,
          hero.buttonLink,
          hero.horizontalTextAlignment,
          hero.verticalTextAlignment,
        );
        break;

      case LandingPageBlockType.ImageText:
        const imageText = from.data as LandingPageImageTextBlockEntity;

        blockData = new LandingPageImageTextBlock(
          imageText.imageUrl,
          imageText.title,
          imageText.text,
        );
        break;

      case LandingPageBlockType.Map:
        const map = from.data as LandingPageMapBlockEntity;

        blockData = new LandingPageMapBlock(map.title);
        break;

      case LandingPageBlockType.Logo:
        const logo = from.data as LandingPageLogoBlockEntity;

        blockData = new LandingPageLogoBlock(
          logo.backgroundColor,
          logo.logoUrl,
        );
        break;

      case LandingPageBlockType.Retailers:
        const retailers = from.data as LandingPageRetailersBlockEntity;

        blockData = new LandingPageRetailersBlock(
          retailers.title,
          retailers.forwardToRetailersPage,
        );
        break;

      case LandingPageBlockType.SocialMedia:
        const socialMedia = from.data as LandingPageSocialMediaBlockEntity;

        blockData = new LandingPageSocialMediaBlock(
          socialMedia.title,
          socialMedia.description,
          socialMedia.fbImage,
        );
        break;

      case LandingPageBlockType.Style:
        const style = from.data as LandingPageStyleBlockEntity;

        blockData = new LandingPageStyleBlock(
          this.colorPaletteMapper.map(style.colorPalette),
          this.fontMapper.map(style.font),
        );
        break;

      case LandingPageBlockType.Video:
        const video = from.data as LandingPageVideoBlockEntity;

        blockData = new LandingPageVideoBlock(
          video.title,
          video.text,
          video.videoUrl,
        );
        break;

      default:
        throw new NotFoundError();
    }

    return new LandingPageBlock(
      from.id,
      from.landingPageId,
      from.type as LandingPageBlockType,
      from.order,
      blockData,
    );
  }
}

export class LandingPageBlockToLandingPageBlockEntityMapper {
  constructor(
    private readonly colorPaletteMapper: ColorPaletteToColorPaletteEntityMapper,
    private readonly fontMapper: FontToFontEntityMapper,
  ) {}

  public map(from: LandingPageBlock): LandingPageBlockEntity {
    let blockData = {};

    switch (from.type) {
      case LandingPageBlockType.Analytics:
        const analytics = from.data as LandingPageAnalyticsBlock;

        blockData = new LandingPageAnalyticsBlockEntity(
          analytics.gTagManagerId,
          analytics.gAnalyticsId,
        );
        break;

      case LandingPageBlockType.Carousel:
        const carousel = from.data as LandingPageCarouselBlock;

        blockData = new LandingPageCarouselBlockEntity(
          carousel.imageUrls,
          carousel.title,
          carousel.text,
          carousel.autoScroll,
        );
        break;

      case LandingPageBlockType.Config:
        const config = from.data as LandingPageConfigBlock;

        blockData = new LandingPageConfigBlockEntity(
          config.language,
          config.faviconUrl,
        );
        break;

      case LandingPageBlockType.Footer:
        const footer = from.data as LandingPageFooterBlock;

        blockData = new LandingPageFooterBlockEntity(
          footer.backgroundColor,
          footer.textColor,
          footer.imprintUrl,
          footer.dataProtectionUrl,
        );
        break;

      case LandingPageBlockType.Hero:
        const hero = from.data as LandingPageHeroBlock;

        blockData = new LandingPageHeroBlockEntity(
          hero.backgroundImageUrl,
          hero.mobileBackgroundImageUrl,
          hero.blockStyle,
          hero.textColor,
          hero.mainText,
          hero.secondaryText,
          hero.callToActionText,
          hero.buttonLink,
          hero.horizontalTextAlignment,
          hero.verticalTextAlignment,
        );
        break;

      case LandingPageBlockType.ImageText:
        const imageText = from.data as LandingPageImageTextBlock;

        blockData = new LandingPageImageTextBlockEntity(
          imageText.imageUrl,
          imageText.title,
          imageText.text,
        );
        break;

      case LandingPageBlockType.Logo:
        const logo = from.data as LandingPageLogoBlock;

        blockData = new LandingPageLogoBlockEntity(
          logo.backgroundColor,
          logo.logoUrl,
        );
        break;

      case LandingPageBlockType.Map:
        const map = from.data as LandingPageRetailersBlock;

        blockData = new LandingPageMapBlockEntity(map.title);
        break;

      case LandingPageBlockType.Retailers:
        const retailers = from.data as LandingPageRetailersBlock;

        blockData = new LandingPageRetailersBlockEntity(
          retailers.title,
          retailers.forwardToRetailersPage,
        );
        break;

      case LandingPageBlockType.SocialMedia:
        const socialMedia = from.data as LandingPageSocialMediaBlock;

        blockData = new LandingPageSocialMediaBlockEntity(
          socialMedia.title,
          socialMedia.description,
          socialMedia.fbImage,
        );
        break;

      case LandingPageBlockType.Style:
        const style = from.data as LandingPageStyleBlock;

        blockData = new LandingPageStyleBlockEntity(
          this.colorPaletteMapper.map(style.colorPalette),
          this.fontMapper.map(style.font),
        );
        break;

      case LandingPageBlockType.Video:
        const video = from.data as LandingPageVideoBlock;

        blockData = new LandingPageVideoBlockEntity(
          video.title,
          video.text,
          video.videoUrl,
        );
        break;
    }

    return new LandingPageBlockEntity(
      from.id,
      from.landingPageId,
      from.type,
      from.order,
      blockData,
    );
  }
}
