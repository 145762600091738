<span class="close-icon" [mat-dialog-close]="false">
  <mat-icon>close</mat-icon>
</span>
<h2 mat-dialog-title *ngIf="actionType">
  {{ "landingPage." + actionType + ".title" | translate }}
</h2>
<mat-dialog-content>
  <p *ngIf="actionType === formActions.Create">
    {{ "landingPage.create.description" | translate }}
  </p>
  <form [formGroup]="form">
    <mat-form-field class="field-title">
      <mat-label>{{ "landingPage.create.name" | translate }}</mat-label>
      <input matInput formControlName="title" />
      <mat-error>{{ "shared.requiredField" | translate }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="false">
    {{ "shared.cancel" | translate }}
  </button>
  <button class="btn btn--primary" (click)="submit()" *ngIf="actionType">
    {{ "landingPage." + actionType + ".action" | translate }}
  </button>
</mat-dialog-actions>
