import { PutInteractor } from "../../../../../harmony/core";
import { UnpublishLandingPageQuery } from "../../data/queries/landing-page-network.query";
import { LandingPage } from "../models/landing.page";

export class UnpublishLandingPageInteractor {
  constructor(private readonly putLandingPage: PutInteractor<LandingPage>) {}

  public async execute(landingPageId: number): Promise<LandingPage> {
    return this.putLandingPage.execute(
      undefined,
      new UnpublishLandingPageQuery(landingPageId),
    );
  }
}
