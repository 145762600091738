import { PutInteractor } from "../../../../../harmony/core";
import { LandingPageService } from "../../../../landing-page/services/landing-page.service";
import { UpdateAllLandingPageBlockQuery } from "../../data/queries/landing-page-block-network.query";
import { LandingPageBlock } from "../models/landing-page.block";

export class UpdateAllLandingPageBlocksInteractor {
  constructor(
    private readonly updateBlocks: PutInteractor<LandingPageBlock[]>,
    private readonly landingPageService: LandingPageService,
  ) {}

  public async execute(
    landingPageBlocks: LandingPageBlock[],
  ): Promise<LandingPageBlock[]> {
    const updatedBlocks = await this.updateBlocks.execute(
      landingPageBlocks,
      new UpdateAllLandingPageBlockQuery(),
    );

    await this.landingPageService.updateCurrentLandingPageBlocks(updatedBlocks);

    return updatedBlocks;
  }
}
