import { Subscription } from "rxjs";
import { UpdateLandingPageInteractor } from "../../../../features/landing-page/domain/interactors/update-landing-page.interactor";
import { LandingPage } from "../../../../features/landing-page/domain/models/landing.page";
import { LandingPageService } from "../../../services/landing-page.service";
import { LandingPageBlockConfigView } from "./landing-page-block-config.component";

export interface LandingPageBlockConfigPresenter {
  attachView(view: LandingPageBlockConfigView): void;
  onEventNgOnInit(): void;
  onEventNgOnDestroy(): void;
  onActionEditTitle(title: string): void;
}

export class DefaultLandingPageBlockConfigPresenter
  implements LandingPageBlockConfigPresenter
{
  private view!: LandingPageBlockConfigView;
  private subscription = new Subscription();
  private landingPage?: LandingPage;

  constructor(
    protected readonly landingPageService: LandingPageService,
    protected readonly updateLandingPage: UpdateLandingPageInteractor,
  ) {}

  public attachView(view: LandingPageBlockConfigView): void {
    this.view = view;
  }

  public onEventNgOnInit(): void {
    this.subscription.add(
      this.landingPageService.currentLandingPage$.subscribe((landingPage) => {
        this.landingPage = landingPage;
        this.view.onDisplayLandingPageTitle(landingPage?.title);
      }),
    );
  }

  public async onActionEditTitle(title: string): Promise<void> {
    this.view.onDisplaySaving(true);
    try {
      await this.updateLandingPage.execute({
        ...this.landingPage,
        title: title,
      } as LandingPage);
    } finally {
      this.view.onDisplaySaving(false);
    }
  }

  public onEventNgOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
