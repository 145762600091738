import { Injectable } from "@angular/core";
import { NavigationBehaviorOptions, Router, UrlTree } from "@angular/router";

export interface RouterService {
  navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationBehaviorOptions,
  ): Promise<boolean>;
}

@Injectable({
  providedIn: "root",
})
export class DefaultRouterService implements RouterService {
  constructor(private router: Router) {}

  public async navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationBehaviorOptions,
  ): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }
}
