import { BrandService } from "../../shared/services/api/brand.service";
import { LandingPageBlockEntity } from "./data/entities/landing-page-block.entity";
import {
  ColorPaletteEntityToColorPaletteMapper,
  ColorPaletteToColorPaletteEntityMapper,
} from "./data/mappers/color-palette.mapper";
import {
  FontEntityToFontMapper,
  FontToFontEntityMapper,
} from "./data/mappers/font.mapper";
import { DeleteLandingPageBlockInteractor } from "./domain/interactors/delete-landing-page-block.interactor";
import { GetAllLandingPageBlocksInteractor } from "./domain/interactors/get-all-landing-page-blocks.interactor";
import { UnpublishLandingPageInteractor } from "./domain/interactors/unpublish-landing-page.interactor";
import { UpdateAllLandingPageBlocksInteractor } from "./domain/interactors/update-all-landing-page-blocks.interactor";
import { LandingPageService } from "../../landing-page/services/landing-page.service";
import { LandingPageSummaryEntity } from "./data/entities/landing-page-summary.entity";
import { LandingPageEntity } from "./data/entities/landing-page.entity";
import { CreateLandingPageBlockInteractor } from "./domain/interactors/create-landing-page-block.interactor";
import { CreateLandingPageInteractor } from "./domain/interactors/create-landing-page.interactor";
import { DeleteLandingPageInteractor } from "./domain/interactors/delete-landing-page.interactor";
import { GetLandingPageBlockInteractor } from "./domain/interactors/get-landing-page-block.interactor";
import { GetLandingPageSummariesInteractor } from "./domain/interactors/get-landing-page-summaries.interactor";
import { GetLandingPageInteractor } from "./domain/interactors/get-landing-page.interactor";
import { PublishLandingPageInteractor } from "./domain/interactors/publish-landing-page.interactor";
import { UpdateLandingPageBlockInteractor } from "./domain/interactors/update-landing-page-block.interactor";
import { UpdateLandingPageInteractor } from "./domain/interactors/update-landing-page.interactor";
import {
  LandingPageBlockEntityToLandingPageBlockMapper,
  LandingPageBlockToLandingPageBlockEntityMapper,
} from "./data/mappers/landing-page-block.mapper";
import { LandingPageSummaryEntityToLandingPageSummaryMapper } from "./data/mappers/landing-page-summary.mapper";
import {
  LandingPageEntityToLandingPageMapper,
  LandingPageToLandingPageEntityMapper,
} from "./data/mappers/landing-page.mapper";
import { LandingPageBlock } from "./domain/models/landing-page.block";
import { LandingPageSummary } from "./domain/models/landing-page.summary";
import { LandingPage } from "./domain/models/landing.page";
import {
  ApiRequestService,
  ArrayMapper,
  Cached,
  DeleteInteractor,
  GetInteractor,
  GetRepositoryMapper,
  PaginationOffsetLimit,
  PaginationOffsetLimitMapper,
  SingleGetDataSourceRepository,
  provideDefaultNetworkDataSource,
  PutInteractor,
  RepositoryMapper,
  SingleDataSourceRepository,
  provideDefaultArrayNetworkDataSource,
} from "../../../harmony/core";

export abstract class LandingPageProvider {
  abstract provideGetLandingPageSummaries(
    brandService: BrandService,
  ): GetLandingPageSummariesInteractor;

  abstract provideGetLandingPage(
    landingPageService: LandingPageService,
  ): GetLandingPageInteractor;

  abstract provideCreateLandingPage(
    brandService: BrandService,
  ): CreateLandingPageInteractor;

  abstract provideUpdateLandingPage(
    landingPageService: LandingPageService,
  ): UpdateLandingPageInteractor;

  abstract providePublishLandingPage(): PublishLandingPageInteractor;

  abstract provideUnpublishLandingPage(): UnpublishLandingPageInteractor;

  abstract provideDeleteLandingPage(): DeleteLandingPageInteractor;

  abstract provideGetAllLandingPageBlocks(
    landingPageService: LandingPageService,
  ): GetAllLandingPageBlocksInteractor;

  abstract provideCreateLandingPageBlock(
    ladingPageService: LandingPageService,
  ): CreateLandingPageBlockInteractor;

  abstract provideGetLandingPageBlock(): GetLandingPageBlockInteractor;

  abstract provideUpdateLandingPageBlock(
    landingPageService: LandingPageService,
  ): UpdateLandingPageBlockInteractor;

  abstract provideUpdateAllLandingPageBlocks(
    landingPageService: LandingPageService,
  ): UpdateAllLandingPageBlocksInteractor;

  abstract provideDeleteLandingPageBlock(
    landingPageService: LandingPageService,
  ): DeleteLandingPageBlockInteractor;
}

export class LandingPageDefaultProvider implements LandingPageProvider {
  constructor(private readonly apiRequestService: ApiRequestService) {}

  public provideGetLandingPage(
    landingPageService: LandingPageService,
  ): GetLandingPageInteractor {
    return new GetLandingPageInteractor(
      new GetInteractor<LandingPage>(this.getLandingPageRepository()),
      landingPageService,
    );
  }

  public provideGetLandingPageSummaries(
    brandService: BrandService,
  ): GetLandingPageSummariesInteractor {
    return new GetLandingPageSummariesInteractor(
      new GetInteractor<PaginationOffsetLimit<LandingPageSummary>>(
        this.getRepositoryPagination(),
      ),
      brandService,
    );
  }

  public provideCreateLandingPage(
    brandService: BrandService,
  ): CreateLandingPageInteractor {
    return new CreateLandingPageInteractor(
      new PutInteractor<LandingPage>(this.getLandingPageRepository()),
      brandService,
    );
  }

  public provideUpdateLandingPage(
    landingPageService: LandingPageService,
  ): UpdateLandingPageInteractor {
    return new UpdateLandingPageInteractor(
      new PutInteractor<LandingPage>(this.getLandingPageRepository()),
      landingPageService,
    );
  }

  public providePublishLandingPage(): PublishLandingPageInteractor {
    return new PublishLandingPageInteractor(
      new PutInteractor<LandingPage>(this.getLandingPageRepository()),
    );
  }

  public provideUnpublishLandingPage(): UnpublishLandingPageInteractor {
    return new UnpublishLandingPageInteractor(
      new PutInteractor<LandingPage>(this.getLandingPageRepository()),
    );
  }

  public provideDeleteLandingPage(): DeleteLandingPageInteractor {
    return new DeleteLandingPageInteractor(
      new DeleteInteractor(this.getLandingPageRepository()),
    );
  }

  public provideGetAllLandingPageBlocks(
    landingPageService: LandingPageService,
  ): GetAllLandingPageBlocksInteractor {
    return new GetAllLandingPageBlocksInteractor(
      new GetInteractor<LandingPageBlock[]>(
        this.getLandingPageBlockArrayRepository(),
      ),
      landingPageService,
    );
  }

  public provideCreateLandingPageBlock(
    landingPageService: LandingPageService,
  ): CreateLandingPageBlockInteractor {
    return new CreateLandingPageBlockInteractor(
      new PutInteractor<LandingPageBlock>(this.getLandingPageBlockRepository()),
      landingPageService,
    );
  }

  public provideGetLandingPageBlock(): GetLandingPageBlockInteractor {
    return new GetLandingPageBlockInteractor(
      new GetInteractor<LandingPageBlock>(this.getLandingPageBlockRepository()),
    );
  }

  public provideUpdateLandingPageBlock(
    landingPageService: LandingPageService,
  ): UpdateLandingPageBlockInteractor {
    return new UpdateLandingPageBlockInteractor(
      new PutInteractor<LandingPageBlock>(this.getLandingPageBlockRepository()),
      landingPageService,
    );
  }

  public provideUpdateAllLandingPageBlocks(
    landingPageService: LandingPageService,
  ): UpdateAllLandingPageBlocksInteractor {
    return new UpdateAllLandingPageBlocksInteractor(
      new PutInteractor<LandingPageBlock[]>(
        this.getLandingPageBlockArrayRepository(),
      ),
      landingPageService,
    );
  }

  public provideDeleteLandingPageBlock(
    landingPageService: LandingPageService,
  ): DeleteLandingPageBlockInteractor {
    return new DeleteLandingPageBlockInteractor(
      new DeleteInteractor(this.getLandingPageBlockRepository()),
      landingPageService,
    );
  }

  @Cached()
  private getLandingPageRepository(): RepositoryMapper<
    LandingPageEntity,
    LandingPage
  > {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      LandingPageEntity,
    );
    const repository = new SingleDataSourceRepository(
      dataSource,
      dataSource,
      dataSource,
    );

    return new RepositoryMapper(
      repository,
      repository,
      repository,
      new LandingPageEntityToLandingPageMapper(),
      new LandingPageToLandingPageEntityMapper(),
    );
  }

  @Cached()
  private getLandingPageBlockRepository(): RepositoryMapper<
    LandingPageBlockEntity,
    LandingPageBlock
  > {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      LandingPageBlockEntity,
    );
    const repository = new SingleDataSourceRepository(
      dataSource,
      dataSource,
      dataSource,
    );

    return new RepositoryMapper(
      repository,
      repository,
      repository,
      new LandingPageBlockEntityToLandingPageBlockMapper(
        new ColorPaletteEntityToColorPaletteMapper(),
        new FontEntityToFontMapper(),
      ),
      new LandingPageBlockToLandingPageBlockEntityMapper(
        new ColorPaletteToColorPaletteEntityMapper(),
        new FontToFontEntityMapper(),
      ),
    );
  }

  @Cached()
  private getLandingPageBlockArrayRepository(): RepositoryMapper<
    LandingPageBlockEntity[],
    LandingPageBlock[]
  > {
    const dataSource = provideDefaultArrayNetworkDataSource(
      this.apiRequestService,
      LandingPageBlockEntity,
    );
    const repository = new SingleDataSourceRepository(
      dataSource,
      dataSource,
      dataSource,
    );

    return new RepositoryMapper(
      repository,
      repository,
      repository,
      new ArrayMapper(
        new LandingPageBlockEntityToLandingPageBlockMapper(
          new ColorPaletteEntityToColorPaletteMapper(),
          new FontEntityToFontMapper(),
        ),
      ),
      new ArrayMapper(
        new LandingPageBlockToLandingPageBlockEntityMapper(
          new ColorPaletteToColorPaletteEntityMapper(),
          new FontToFontEntityMapper(),
        ),
      ),
    );
  }

  @Cached()
  private getRepositoryPagination(): GetRepositoryMapper<
    PaginationOffsetLimit<LandingPageSummaryEntity>,
    PaginationOffsetLimit<LandingPageSummary>
  > {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<LandingPageSummaryEntity>,
    );
    const repository = new SingleGetDataSourceRepository(dataSource);

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new LandingPageSummaryEntityToLandingPageSummaryMapper(),
      ),
    );
  }
}
