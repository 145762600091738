import { Component, EventEmitter, Inject } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

export enum LandingPageFormDialogAction {
  Create = "create",
  Edit = "edit",
}

export interface LandingPageFormDialogData {
  landingPageTitle?: string;
}

@Component({
  selector: "app-landing-page-form-dialog",
  templateUrl: "./landing-page-form-dialog.component.html",
  styleUrls: ["./landing-page-form-dialog.component.scss"],
})
export class LandingPageFormDialogComponent {
  public onSave = new EventEmitter<string>();
  public form: UntypedFormGroup;
  public actionType: LandingPageFormDialogAction;
  public formActions = LandingPageFormDialogAction;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: LandingPageFormDialogData,
  ) {
    this.actionType = data?.landingPageTitle
      ? LandingPageFormDialogAction.Edit
      : LandingPageFormDialogAction.Create;

    this.form = new UntypedFormGroup({
      title: new UntypedFormControl(data?.landingPageTitle, [
        Validators.required,
      ]),
    });
  }

  public submit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.onSave.emit(this.form.controls.title.value);
    this.dialog.closeAll();
  }
}
