import { mapValueToEnumKey } from "../../../../shared/functions/string-to-enum.mapper";
import { LandingPageStatus } from "../../domain/models/landing.page";
import { LandingPageSummaryEntity } from "../entities/landing-page-summary.entity";
import { LandingPageSummary } from "../../domain/models/landing-page.summary";

export class LandingPageSummaryEntityToLandingPageSummaryMapper {
  public map(from: LandingPageSummaryEntity): LandingPageSummary {
    return new LandingPageSummary(
      from.id,
      from.title,
      from.uuid,
      from.brandId,
      mapValueToEnumKey(LandingPageStatus, from.status),
      from.logoBlockId,
      from.associatedCampaigns,
      from.uri,
      from.backgroundImgUrl,
      from.logoUrl,
    );
  }
}
