import { Subscription } from "rxjs";
import { GetAllLandingPageBlocksInteractor } from "../../../features/landing-page/domain/interactors/get-all-landing-page-blocks.interactor";
import { GetLandingPageInteractor } from "../../../features/landing-page/domain/interactors/get-landing-page.interactor";
import {
  LandingPageBlock,
  LandingPageBlockType,
} from "../../../features/landing-page/domain/models/landing-page.block";
import { LandingPageService } from "../../services/landing-page.service";
import { LandingPageBuilderContainerView } from "./landing-page-builder-container.component";

export interface LandingPageBuilderContainerPresenter {
  attachView(view: LandingPageBuilderContainerView): void;
  onEventBlockChanged(blockId: number): void;
  onEventNgOnInit(landingPageId: number, blockId: number): void;
  onEventNgOnDestroy(): void;
  onActionBlockEdited(block: LandingPageBlock): void;
}

export class DefaultLandingPageBuilderContainerPresenter
  implements LandingPageBuilderContainerPresenter
{
  private view!: LandingPageBuilderContainerView;
  private subscriptions!: Subscription;
  private currentBlockId: number | undefined;
  private landingPageId: number | undefined;
  private currentBlocks: LandingPageBlock[] = [];

  constructor(
    private readonly getLandingPage: GetLandingPageInteractor,
    private readonly getLandingPageBlocks: GetAllLandingPageBlocksInteractor,
    private readonly landingPageService: LandingPageService,
  ) {}

  public attachView(view: LandingPageBuilderContainerView): void {
    this.view = view;
  }

  public async onEventNgOnInit(
    landingPageId: number,
    blockId: number,
  ): Promise<void> {
    this.landingPageId = landingPageId;
    this.currentBlockId = blockId;
    const getLandingPage = this.getLandingPage.execute(landingPageId);
    const getLandingPageBlocks =
      this.getLandingPageBlocks.execute(landingPageId);
    await Promise.all([getLandingPage, getLandingPageBlocks]);
    this.listenBlocksChanges();
  }

  public async onEventBlockChanged(blockId: number): Promise<void> {
    this.currentBlockId = blockId;
    this.showCorrespondingSection();
  }

  public onEventNgOnDestroy(): void {
    // The order here is important, we want to first unsubscribe so we don't
    // react to further `currentLandingPageBlocks$` changes
    this.subscriptions?.unsubscribe();
    this.landingPageService.clearCurrentLandingPage();
  }

  private listenBlocksChanges(): void {
    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.landingPageService.currentLandingPageBlocks$.subscribe((blocks) => {
        if (!blocks) {
          return;
        }
        this.currentBlocks = blocks;
        this.view.onDisplayLandingPageBlocks(blocks);
        this.showCorrespondingSection();
      }),
    );
  }

  private showCorrespondingSection(): void {
    const currentBlock = this.currentBlocks.find(
      (block) => block.id === this.currentBlockId,
    );
    const isDeleted = !!this.currentBlockId && !currentBlock;
    const isFirstEntrance = !this.currentBlockId && !currentBlock;
    const isRedirecting = isDeleted || isFirstEntrance;

    if (isRedirecting) {
      const blockTypeToShow = isDeleted
        ? LandingPageBlockType.Hero
        : LandingPageBlockType.Logo;

      const section = this.currentBlocks.find(
        (block) => block.type === blockTypeToShow,
      );
      this.view.onNotifyBlockRedirection(this.landingPageId, section?.id);
      return;
    }

    this.view.onDisplaySection(currentBlock);
  }

  public onActionBlockEdited(block: LandingPageBlock): void {
    this.view.onDisplayPreviewForSection(block);
  }
}
